<template>
  <div class="Part">
    <el-row style="padding: 5px 5px 5px 0;" ref="spuRow">
      <el-form>
        <el-col :span="7">
          <el-form-item label="设备名称:" label-width="90px">
            <el-input v-model="deviceName" @change="identificationRecord" size="mini" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="设备SN:" label-width="75px">
            <el-input v-model="deviceSn" @change="identificationRecord" size="mini" clearable></el-input>
          </el-form-item>
        </el-col>
         <el-col :span="7">
          <el-form-item label="比对结果:" label-width="75px">
               <el-select size="mini" clearable v-model="comparisonResult" placeholder="请选择" @change="identificationRecord">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
          <el-col :span="7">
          <el-form-item label="人员姓名:" label-width="90px">
            <el-input v-model="personnelName" @change="identificationRecord" size="mini" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="人员类型:" label-width="75px">
               <el-select size="mini" clearable v-model="personnelType" placeholder="请选择"  @change="identificationRecord">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
          <el-col>
            <el-form-item label="识别时间段:" label-width="100px">
           <el-date-picker
          v-model="creationTime"
          @change="creationTimeChange"
          :unlink-panels="true"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          size="mini"
        ></el-date-picker>
          </el-form-item>
          </el-col>
      </el-form>
    </el-row>
    <div>
      <el-table
        :data="dataList"
        highlight-current-row
        @row-dblclick="dbClickData"
        :height='tableHeight'
        v-loading="loading"
        style="width:100%"
        >
        <el-table-column label="安装位置" align="center" min-width="50">
          <template slot-scope="scope">
            <div>{{scope.row.hsAddCommunity}}{{scope.row.hsAddBuilding}}{{scope.row.hsAddDoorplateno}}{{scope.row.secondaryTypeName}}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="secondaryTypeName" label="安装位置" align="center" min-width="50"></el-table-column> -->
        <el-table-column prop="deviceName" label="设备名称" align="center" min-width="50"></el-table-column>
        <el-table-column prop="devSn" label="设备SN" align="center" min-width="50"></el-table-column>
        <el-table-column prop="jftiPersonName" label="人员姓名" align="center" min-width="50">
           <template slot-scope="scope">
             <div>{{scope.row.jftiPersonName?scope.row.jftiPersonName:'未知'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="jftiPasernType" label="人员类型" align="center" min-width="50">
           <template slot-scope="scope">
             <div>{{scope.row.jftiPasernType?scope.row.jftiPasernType:'陌生人员'}}</div>
          </template>
        </el-table-column>
        <el-table-column  label="识别照片" align="center" min-width="50">
           <template slot-scope="scope">
            <img :src="scope.row.jftiPhotoUrl" style="width: 60px;height: 50px;"/>
          </template>
        </el-table-column>
           <el-table-column label="人体体温" align="center" min-width="50">
           <template slot-scope="scope">
             <div>{{scope.row.jftiTemperature}}{{scope.row.jftiTemperature?'℃':'未知'}}</div>
          </template>
        </el-table-column>
        <el-table-column  label="活体检测" align="center" min-width="50">
          <template slot-scope="scope">
             <div>{{scope.row.jfriAliveType=='1'?'活体':scope.row.jfriAliveType=='2'?'非活体':'未判断'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="jftiRecType" label="比对模式" align="center" min-width="50">
           <template slot-scope="scope">
             <div>{{scope.row.jftiRecType=='1'?'本地':'云端'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="jftiType" label="比对结果" align="center" min-width="50">
            <template slot-scope="scope">
             <div>{{scope.row.jftiType=='1'?'成功':'失败'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="jfriShowTime" label="识别时间" align="center" min-width="50"></el-table-column>
      </el-table>
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        align="center"
        :page-count="pages"
        :page-size="size" 
        :total="total"
        :current-page="current"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange" 
        :page-sizes="[5,10,20,40,100]"
      ></el-pagination>
    </div>
    <!-- <el-dialog v-dialogDrag title="查看" :visible.sync="detailsDialog" :close-on-click-modal="false" class="look-details" width="700px">
      <div>123</div>
    </el-dialog> -->
  </div>
</template>
<script>
export default {
  name:'superuser',
  data(){
    return{
      dataList: [],
      tableHeight: window.innerHeight - 150,
      deviceName:"",
      deviceSn:"",
      personnelName:"",
      personnelType:"",
      comparisonResult:"",
      creationTime: [],
      detailsDialog: false,
      labelWidth: '150px',
      loading: false,
      total: 0,      //总条数
      size: 20,      //每页的条数
      pages: 0,      //每页数据
      current: 0,    //初始页
      list:[],
      options2: [
      {
        value: 1,
        label: '比对成功'
      },{
        value: 2,
        label: '比对失败'
      }
     ],
      options1: [
      {
        value: 1,
        label: '陌生人员'
      },{
        value: 2,
        label: '已知人员'
      }
     ]
    }
  },
  created() {
    this.identificationRecord();
  },
  methods: {
    creationTimeChange(e){
      this.identificationRecord();
    },
    identificationRecord(){
      this.loading =true
       this.$axios({
        method: 'GET',
        url: '/common2/faceRecognitionInformations',
        params:{
          cursor:this.current,
          limit: this.size,
          devName:this.deviceName,  // 设备名称
          jfriDeviceKey:this.deviceSn,  // 设备SN
          jftiPersonName:this.personnelName,// 用户名
          jftiType:this.comparisonResult,// 比对结果 1:比对成功 2:比对失败
          faceType:this.personnelType, // 人员类型 1:陌生人 0:用户
          startTime: this.creationTime[0],  // 开始时间
          endTime: this.creationTime[1] // 结束时间
        }
      }).then(res => {
        this.loading =false
        this.dataList =res.data.result.records||[]
        this.total =res.data.result.total
      }).catch(err => {
        this.isError(err, this);
      })
    },
    dbClickData(e){
      //  this.detailsDialog = true
       console.log(e,'eee')
    },
    handleCurrentChange(cursor){
      this.current = cursor
      this.identificationRecord();
    },
    handleSizeChange(limit){
      this.size = limit
      this.identificationRecord();
    },
  }
}
</script>
<style lang="scss" scoped>
.el-form .el-form-item{
  /* padding-bottom: 0px; */
}
  .operation-1{
    color: #409EFF;
    margin-right: 5px;
    cursor: pointer;
  }
  .operation-2{
    color: #F56C6C;
    cursor: pointer;
  }
  .look-details{
    >>> .el-dialog__header{
      border-bottom: 1px solid #eeefef;
    }
  }
  .look-row{
    >>> .el-form-item__label{
      text-align: left;
      color: #909399;
    }
  }
</style>
